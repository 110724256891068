/* Keep related-images */
.related-images {
  grid-area: related-images;
  display: grid;
  grid-template-columns: repeat(
    4,
    1fr
  ); /* Is the same as below with repeat(4, 1fr) */
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  grid-gap: 1rem;
}

.related-images .icon {
  background-color: white;
  flex: 1 1 15rem;
}

.related-images .icon img {
  max-width: 100%;
}

.view-btn {
  padding: 1rem 3rem;
  font-family: var(--heading-font);
  color: white;
  background: black;
  border: none;
  transition: 0.4s;
}

.view-btn:hover {
  cursor: pointer;
  color: transparent;
  background: white;
  color: black;
}

.nav-link-wrapper {
  width: 85%;
  overflow: hidden;
}

.drumthesia-container {
  height: 3rem;
  margin-top: 34rem;
}

.project-image-mask {
  position: absolute;
  opacity: 0.7;
  background: var(--black);
  width: 85%;
  top: 0;
  left: 7.5%;
  background: linear-gradient(
    165deg,
    rgb(0, 0, 0) 16%,
    rgba(2, 0, 36, 1) 49%,
    rgba(19, 0, 40, 1) 80%
  );
  transition: height 1.5s ease;
  height: 0;
}

.project-card:hover .project-image-mask {
  transition: height 0.3s ease-in;
  height: 60rem;
}

.project-info {
  transition: all 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.2s;
  transform: translate(0, 0) scale(1);
}

.project-card:hover .project-info {
  transform: translate(100px, 45px) scale(1.2);
}

.asset {
  width: 37%;
  top: 13rem;
  right: 9rem;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.logo-asset-width {
  width: 29%;
}

.project-card:hover .asset {
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0.6s;
  transform: translateY(250px);
  opacity: 1;
}

/* TODO: Create styling for every other project card */
.project-card-wrapper:nth-child(2n + 1) {
  background-color: white; /* This is for changing every other card's styling */
}

@media screen and (max-width: 600px) {
  .project-card:hover .project-image-mask {
    display: none;
  }
  .project-card:hover .project-info {
    transform: translate(0, 0) scale(1);
  }
  .project-card:hover .asset {
    display: none;
  }
}
