.header nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  width: 34%;
}

.header nav ul li {
  z-index: 3;
}

.navbar {
  margin: auto;
  width: 82%;
}

.navbar-nav {
  padding-top: 5rem;
  height: var(--navbar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-nav a, .navbar-nav div {
  font-size: 1.8rem;
  font-family: var(--heading-font);
  color: #b0b2c3c9;
  transition-duration: 0.3s;
  /* cursor: pointer; */
}

.navbar-nav a:hover, .navbar-nav div:hover {
  color: #ffffff;
  cursor: pointer;
}

.navbar-nav img {
  transition-duration: 0.3s;
}

.navbar-nav img:hover {
  opacity: 0.7;
}

.navbar-logo {
  width: 74%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -user-drag: none;
}

.navbar-logo-link {
  width: 8rem;
  z-index: 7;
}

.navbar-menu li {
  opacity: 1;
}

/* Mobile Menu CSS */

.menu-checkbox {
  /* Double-sized Checkboxes */
  -moz-transform: scale(5.7, 4.85); /* FF */
  -ms-transform: scale(5.7, 4.85); /* IE */
  -o-transform: scale(5.7, 4.85); /* Opera */
  -webkit-transform: scale(5.7, 4.85); /* Safari and Chrome */
  border-radius: 100%;
  cursor: pointer;
  display: none;
  opacity: 0;
  position: absolute;
  right: 65px;
  transform: scale(5.7, 4.85);
  z-index: 8;
}

.hamburger {
  border-radius: 50%;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: none;
  font: inherit;
  margin: 0;
  overflow: visible;
  padding: 15px 15px;
  position: absolute;
  right: 37px;
  text-transform: none;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  transition-timing-function: linear;
  transition: all 0.4 ease;
  transition: box-shadow 0.2s;
  z-index: 7;
}

.menu-checkbox:hover ~ .hamburger {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
}

.hamburger-box {
  width: 40px;
  height: 24px;
  position: relative;
  display: none;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: #fff;
  border-radius: 4px;
  height: 4px;
  position: absolute;
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: ease;
  width: 40px;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/* Checked Input Animation */
.menu-checkbox:checked ~ .hamburger--emphatic .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.menu-checkbox:checked ~ .hamburger--emphatic .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.menu-checkbox:checked ~ .hamburger--emphatic .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.menu-block-one {
  background-color: var(--space-cadet-blue);
  display: none;
  height: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
}

.menu-block-two {
  background-color: var(--primary-bg-color);
  display: none;
  height: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

.navbar-nav .menu-checkbox:checked ~ .navbar-menu a {
  font-size: 3rem;
}

.navbar-nav .menu-checkbox:checked ~ .navbar-menu li {
  margin-bottom: 8rem;
}

/* Media Queries */
@media only screen and (max-width: 1200px) {
  .navbar-nav {
    justify-content: center;
  }

  .hamburger,
  .hamburger-box,
  .menu-checkbox {
    display: inline-block;
  }

  .menu-block-one,
  .menu-block-two {
    display: block;
  }

  .navbar-menu li {
    opacity: 0;
    margin-bottom: 8rem;
  }
}

@media only screen and (max-width: 600px) {
  .hamburger {
    right: 17px;
  }

  .menu-checkbox {
    right: 45px;
  }
}
