.about-hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  margin-top: calc(var(--navbar-height) * -1);
  position: relative;
  min-height: 75vh;
}

.about-scroll-link {
  position: absolute;
  bottom: 2%;
}

.about-subtitle {
  font-family: var(--heading-font);
  font-size: var(--lead);
  font-weight: 600;
}

.about-description {
  font-size: var(--lead);
  line-height: 1.5;
}

.about-description-img {
  width: 50vw;
  height: 55vw;
  background: url("https://source.unsplash.com/featured/?background,dark")
    no-repeat center;
  background-size: cover;
}

.about-cta-button {
  background-color: var(--primary-bg-color);
  border: none;
  color: white;
  margin-top: 3rem;
  padding: 2rem 8rem;
  font-size: 3rem;
  transition: 0.4s;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 100vh;
}

.about-cta-button:hover {
  border: 1px solid var(--primary-bg-color);
}

.about-job-title {
  font-size: var(--lead);
}

.about-description {
  text-align: left;
}

.value-icon {
  margin: 0 20px 40px;
  width: 200px;
  height: auto;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px grey;
  transition: 0.3s;
}

.value-icon:hover {
  box-shadow: 0 2px 10px grey;
}

.value-icons {
  flex-wrap: wrap;
  max-width: 1199px;
}

.value-icons > .value-icon > h3 {
  font-size: var(--h6);
}

.value-icon > p {
  font-size: var(--lead);
}

.world-impact-icon {
  transform: scale(1.5);
  padding: 23px 0 27px 0;
}

.about-info-layout {
  margin-bottom: 50px;
}

/* Media Queries */
@media only screen and (max-width: 1120px) {
  .about-info-layout {
    width: 75%;
  }

  .about-description-img {
    margin-top: 40px;
    width: 100vw;
  }
}

@media only screen and (max-width: 700px) {
  .about-description-img {
    width: 100vw;
    height: 45vh;
  }
}
