.project-hero {
  /* background: url("https://images.pexels.com/photos/580679/pexels-photo-580679.jpeg")
    no-repeat center; */
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: calc(var(--navbar-height) * -1);
  position: relative;
  min-height: 75vh;
}

.hero-icon-list {
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

.hero-icon {
  --size: 13rem;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  margin: 0 4rem 4rem;
}

.project-info-section {
  background: url("https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940")
    no-repeat center;
  background-size: cover;
  padding-bottom: 20rem;
}

.project-description {
  font-size: var(--lead);
  line-height: 1.5;
}

.first-gallery-img {
  width: 100%;
  max-width: 740px;
  height: auto;
  margin-top: -25%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -user-drag: none;
}

.gallery-img {
  width: calc(80% - 20px);
  height: auto;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -user-drag: none;
}

.project-bg-img {
  background: url("https://via.placeholder.com/800x450.png?text=Cool+Web+App+Placeholder")
    no-repeat center;
  background-size: cover;
  width: 100%;
  height: auto;
  padding: 35rem 0;
}

.mobile-images {
  padding-top: 10rem;
  justify-content: space-around;
}

.mobile-view-image {
  background: url("https://via.placeholder.com/300x600?text=300x600+Mobile+View+Example")
    no-repeat center;
  width: 30rem;
  height: 75rem;
  margin-bottom: -7.5rem;
}

.next-project-img {
  background: url("https://images.pexels.com/photos/952670/pexels-photo-952670.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940")
    no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  padding: 22vh 0;
}

.project-scroll-link {
  position: absolute;
  bottom: 2%;
}

.scroll-link-text {
  font-size: var(--lead);
  font-family: var(--heading-font);
}

.next-project-arrow {
  position: absolute;
  right: 2%;
}

/* Media Queries */
@media only screen and (min-width: 1000px) {
  .mobile-images {
    margin: 0 12rem;
  }
  .project-description {
    margin-bottom: 12rem;
  }
}

@media only screen and (max-width: 684px) {
  .mobile-view-image {
    width: 20rem;
  }
}

@media only screen and (max-width: 500px) {
  .mobile-view-image {
    width: 15rem;
  }
}
