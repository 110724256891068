/* Look into linear-gradients */
/* When page changes fade into new page */

.hero {
  /* grid-area: hero; */
  /* grid-column: 1 / 2; */
  /* grid-row-start: 2 / 3; */
  min-height: 100vh;
  /* Test other images */
  /* TODO: Image has bad looking compression in production */
  /* background: url("https://images.hdqwalls.com/download/splash-art-gradient-8k-ui-2560x1440.jpg")
    no-repeat center center fixed; */
  /* background: url("../../assets/img/CoolGeometricBackground-1.jpg")
    no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-top: calc(var(--navbar-height) * -1);
  color: white;
  position: relative;
  /* background-color: black; */
  z-index: 1;
}

.hero h1 {
  font-size: 7rem;
}

.hero h3 {
  font-size: 3rem;
  font-family: serif;
}

.hero button {
  background-color: transparent;
  color: white;
  margin-top: 3rem;
  padding: 2rem 17rem;
  font-size: 3rem;
  transition: 0.4s;
  white-space: nowrap;
  border: 1px solid white;
}

/* Media Queries */
@media only screen and (max-width: 1200px) {
  .hero button {
    padding: 2rem 15rem;
  }
}

@media only screen and (max-width: 600px) {
  .hero button {
    padding: 2rem 10rem;
  }
}
