.footer {
  background-color: var(--primary-bg-color);
}

.footer p {
  color: whitesmoke;
}

.footer-logo {
  width: 8rem;
}

.footer-nav {
  width: 43%;
  margin: auto;
  list-style: none;
  font-family: var(--heading-font);
  font-size: var(--h6);
  display: flex;
  justify-content: space-around;
}

.footer-link {
  transition-duration: 0.3s;
}

.footer-link:hover {
  color: #b0b2c3c9;
}

.footer-copyright {
  font-size: var(--lead);
}

/* Media Queries */
@media only screen and (max-width: 770px) {
  .footer-nav {
    width: 80%;
  }
}

@media only screen and (max-width: 432px) {
 .footer-nav {
   width: 42%;
   flex-wrap: wrap;
   padding-bottom: 0;
 } 

 .footer-nav > * {
   margin-bottom: 1rem;
 }
}