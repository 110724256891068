.loading {
  position: fixed;
  width: 100%;
  height: 100%;
}

.loading-background-front {
  background-color: rgb(0, 0, 37);
  z-index: 140;
}

.loading-background-back {
  background-color: rgb(0, 0, 114);
  z-index: 130;
}

.loader-wrapper {
  width: 95%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-shapes {
  height: 400px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.loading-shape {
  width: 100px;
  height: 100px;
  margin: 15px;
}

.one {
  background-color: rgb(0, 93, 162);
}

.two {
  background-color: rgb(0, 92, 162);
}

.three {
  background-color: rgb(0, 93, 162);
}

.four {
  background-color: rgb(0, 93, 162);
}
