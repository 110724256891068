/* Components */

.main {
  grid-area: main;
}

.noselect {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.vh-25 {
  min-height: 25vh;
}

.vh-35 {
  min-height: 35vh;
}

.vh-50 {
  min-height: 50vh;
}

.vh-75 {
  min-height: 75vh;
}

.vh-full {
  min-height: 100vh;
}

.block {
  display: block;
}

.clear-button-styling {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
