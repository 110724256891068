.container {
  margin: 0 auto;
  width: 95%;
  max-width: 144rem;
}

/* Positioning */

.absolute {
  position: absolute;
}
.sticky {
  position: sticky;
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}

.right {
  right: 0;
}
.left {
  left: 0;
}
.top {
  top: 0;
}
.bottom {
  bottom: 0;
}

.middle {
  left: 50%;
  transform: translateX(-50%);
}

.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}
.z-5 {
  z-index: 5;
}
.z-6 {
  z-index: 6;
}
.z-7 {
  z-index: 7;
}
.z-8 {
  z-index: 8;
}
.z-9 {
  z-index: 9;
}
.z-10 {
  z-index: 10;
}

/* Flex */

.flex {
  display: flex;
}

.flex-center {
  justify-content: center;
}
.flex-end {
  justify-content: flex-end;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}

.flex-top {
  align-items: flex-start;
}
.flex-middle {
  align-items: center;
}
.flex-bottom {
  align-items: flex-end;
}
.column {
  flex-direction: column;
}
.row {
  flex-direction: row;
}

/* Grid */

.grid-column-gap-1 {
  grid-column-gap: 1rem;
}

/* Custom Layout */

.layout {
  display: grid;
  grid-template-areas:
    "header"
    "hero"
    "main"
    "related-images"
    "footer";
}

.header {
  grid-area: header;
  /* grid-column: 1 / 4; */
  /* grid-row: 1 / 2; */
  color: #9f9c9c;
  text-transform: uppercase;
}

.overflow-hidden {
  overflow: hidden;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}
