.slider-block {
  position: relative;
  height: 50rem;
  width: 90%;
  overflow: hidden;
  color: white;
  padding: 2rem;
}

.slider-block > * {
  position: relative;
  z-index: 1;
  transition: transform 0.2s ease;
}

.slider-block:hover > * {
  transform: scale(1.1);
}

.slider-block::after {
  content: "";
  background-color: black;
  opacity: 0.9;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  transition: left 0.5s ease;
}

.slider-block:hover::after {
  left: 0;
}

.project-image {
  height: 60rem;
  /* width: 85%; */
  overflow: hidden;
  color: white;
  padding: 3rem;
  box-shadow: 0 20px 80px 0 rgb(0 0 0 / 45%);
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
}

.project-image h2 {
  font-size: 4.5rem;
}

.project-image p {
  font-size: 1.6rem;
}

@media screen and (max-width: 600px) {
  .project-image h2 {
    font-size: 2.5rem;
  }
}
