/* Globals */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 10px;
}

::selection {
  /* background-color: white;
  color: black; */
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

body {
  background-color: white;
  color: #434344;
  font-family: var(--base-font);
  font-weight: 400;
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font);
}

h1, .h1 {font-size: var(--h1)}
h2, .h2 {font-size: var(--h2)}
h3, .h3 {font-size: var(--h3)}
h4, .h4 {font-size: var(--h4)}
h5, .h5 {font-size: var(--h5)}
h6, .h6 {font-size: var(--h6)}

a {
  color: inherit;
  text-decoration: none;
}

p {
  font-size: 1.5rem;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px black inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill
{
 -webkit-text-fill-color: white !important;
}

footer {
  grid-area: footer;
  /* grid-column: 1 / 4; */
  /* grid-row: 6 / 7; */
  color: #fff;
  text-align: center;
  padding: 20px 0;
  background-color: #9f9c9c;
}

footer p {
  color: #434344;
  font-size: var(--base-font-size);
  padding: 10px;
}
