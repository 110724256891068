.experiment-list { display: flex; flex-wrap: wrap; }

.experiment {
  padding-bottom: 2rem;
  display: block;
  box-shadow: -0.5rem 0 1rem lightgrey;
  flex-basis: 30rem;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.experiment > * {
  position: relative;
  z-index: 1;
}

.experiment::after {
  content: "";
  background-color: var(--primary-bg-color);
  position: absolute;
  height: 100%;
  width: 100%;
  top: -100%;
  transition: top 0.4s ease 0.1s;
}

.experiment-image {
  display: block;
  --size: 23rem; /* Can change to 18rem for smaller screens */
  width: var(--size);
  height: var(--size);
  background-position: center;
  background-size: cover;
  /* background-image: url("https://via.placeholder.com/230.png?text=Nav-Bar+Placeholder"); */
  margin: 3rem auto;
  box-shadow: 0 1rem 2rem var(--manatee);
  border-radius: 50%;
  overflow: hidden;
  transition: transform 0.4s ease;
}

.experiment-text {
  font-size: 1.6rem;
  padding: 0 1.6rem;
  color: black;
  transition: color 0.2s ease 0.15s;
}

.experiment-title {
  font-family: var(--heading-font);
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.experiment:hover .experiment-image {
  transform: scale(1.1);
}

.experiment:hover::after {
  transition: top 0.4s ease 0.3s;
  top: 0;
}

.experiment:hover .experiment-text {
  transition-delay: 0.45s;
  color: white;
}
