.mx-auto {margin: 0 auto}
.my-auto {margin: auto}

.m-1 {margin: 1rem}
.m-2 {margin: 2rem}
.m-3 {margin: 3rem}
.m-4 {margin: 4rem}
.m-5 {margin: 5rem}
.m-6 {margin: 6rem}
.m-7 {margin: 7rem}

.mb-1 {margin-bottom: 1rem}
.mb-2 {margin-bottom: 2rem}
.mb-3 {margin-bottom: 3rem}
.mb-4 {margin-bottom: 4rem}
.mb-5 {margin-bottom: 5rem}
.mb-6 {margin-bottom: 6rem}
.mb-7 {margin-bottom: 7rem}
.mb-8 {margin-bottom: 8rem}
.mb-9 {margin-bottom: 9rem}
.mb-10 {margin-bottom: 10rem}
.mb-11 {margin-bottom: 11rem}
.mb-12 {margin-bottom: 12rem}
.mb-13 {margin-bottom: 13rem}
.mb-14 {margin-bottom: 14rem}

.mt-1 {margin-top: 1rem}
.mt-2 {margin-top: 2rem}
.mt-3 {margin-top: 3rem}
.mt-4 {margin-top: 4rem}
.mt-5 {margin-top: 5rem}
.mt-6 {margin-top: 6rem}
.mt-7 {margin-top: 7rem}
.mt-8 {margin-top: 8rem}
.mt-9 {margin-top: 9rem}

.my-1 {margin: 1rem 0}
.my-2 {margin: 2rem 0}
.my-3 {margin: 3rem 0}
.my-4 {margin: 4rem 0}
.my-5 {margin: 5rem 0}

.mx-1 {margin: 0 1rem}
.mx-2 {margin: 0 2rem}
.mx-3 {margin: 0 3rem}
.mx-4 {margin: 0 4rem}
.mx-5 {margin: 0 5rem}

.pb-1 {padding-bottom: 1rem}
.pb-2 {padding-bottom: 2rem}
.pb-3 {padding-bottom: 3rem}
.pb-4 {padding-bottom: 4rem}
.pb-5 {padding-bottom: 5rem}
.pb-6 {padding-bottom: 6rem}
.pb-7 {padding-bottom: 7rem}
.pb-8 {padding-bottom: 8rem}
.pb-9 {padding-bottom: 9rem}

.pt-1 {padding-top: 1rem}
.pt-2 {padding-top: 2rem}
.pt-3 {padding-top: 3rem}
.pt-4 {padding-top: 4rem}
.pt-5 {padding-top: 5rem}
.pt-6 {padding-top: 6rem}
.pt-7 {padding-top: 7rem}

.py-1 {padding: 1rem 0}
.py-2 {padding: 2rem 0}
.py-3 {padding: 3rem 0}
.py-4 {padding: 4rem 0}
.py-5 {padding: 5rem 0}
.py-6 {padding: 6rem 0}
.py-7 {padding: 7rem 0}
.py-8 {padding: 8rem 0}
.py-9 {padding: 9rem 0}
.py-10 {padding: 10rem 0}

.px-1 {padding: 0 1rem}
.px-2 {padding: 0 2rem}
.px-3 {padding: 0 3rem}
.px-4 {padding: 0 4rem}
.px-5 {padding: 0 5rem}
.px-6 {padding: 0 6rem}
.px-7 {padding: 0 7rem}
.px-8 {padding: 0 8rem}
.px-9 {padding: 0 9rem}
.px-10 {padding: 0 10rem}

.p-1 {padding: 1rem}
.p-2 {padding: 2rem}
.p-3 {padding: 3rem}
.p-4 {padding: 4rem}
.p-5 {padding: 5rem}
.p-6 {padding: 6rem}
.p-7 {padding: 7rem}

