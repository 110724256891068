.modal {
  background: #0005;
  position: fixed;
  inset: 0;
  border-radius: 5px;
  z-index: 100;
  opacity: 0;
}

.modal-width {
  width: 850px;
  position: relative;
}

.contact-link {
  font-family: var(--heading-font);
  font-size: 1.8rem;
  text-transform: uppercase;
  background: transparent;
  outline: none;
  border: none;
  color: #b0b2c3c9;
  cursor: pointer;
  transition: color 0.5s;
}

.contact-link:hover {
  color: white;
}

.contact-form {
  /* background-color: black; */
  background-color: var(--primary-bg-color);
  padding: 3rem;
  width: 100%;
  min-width: 28.5rem;
  max-width: 100%;
  opacity: 0;
  position: relative;
  overflow: hidden;
}

.contact-form label {
  font-family: var(--heading-font);
  font-size: var(--h6);
}

.contact-form input,
label {
  display: block;
  margin-bottom: 1rem;
}

.contact-form input {
  height: 5rem;
}

.form-field input,
textarea {
  width: 100%;
  background: transparent;
  border: none;
  color: var(--white);
  border-bottom: 2px solid white;
  outline: none;
  font-family: var(--heading-font);
  max-height: 500px;
}

input:not([type="checkbox"]),
textarea,
select {
  font-size: var(--lead);
}

textarea {
  width: 100%;
  max-width: 100%;
  min-height: 20rem;
  height: 20rem;
}

.submit-btn {
  width: 100%;
  padding: 1.5rem 3rem;
  font-family: var(--heading-font);
  font-size: var(--lead);
  background: var(--primary-bg-color);
  color: white;
  outline: none;
  border: 1px solid white;
  transition: 0.4s;
}

.submit-btn:hover {
  background: white;
  color: var(--primary-bg-color);
}

.modal-close {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 4rem;
  width: 4rem;
  font-size: 4rem;
  border: none;
  background: transparent;
  color: var(--manatee);
  transition: color 0.3s;
  z-index: 120;
}

.modal-close:hover {
  color: white;
}

.modal-close:hover,
.submit-btn:hover {
  cursor: pointer;
}

.cta-button:hover {
  color: var(--primary-bg-color);
  background-color: white;
  cursor: pointer;
}

.line-draw-up {
  position: absolute;
  background: white;
  left: 0px;
  top: 0px;
  width: 11.5px;
  height: 657px;
}

.line-draw-right {
  position: absolute;
  background: white;
  right: 0px;
  top: 0px;
  width: 970px;
  height: 11.5px;
}

.line-draw-down {
  position: absolute;
  background: white;
  right: 0px;
  bottom: 657px;
  width: 11.5px;
  height: 657px;
}

.line-draw-left {
  position: absolute;
  background: white;
  left: 970px;
  bottom: 0px;
  width: 970px;
  height: 11.5px;
}

.contact-form-outline {
  display: block;
  background-color: transparent;
  padding: 3rem;
  width: 100%;
  min-width: 28.5rem;
  max-width: 100%;
  inset: 0;
  margin: auto;
  height: 65.6rem;
  opacity: 1;
  position: absolute;
  overflow: hidden;
}

.contact-form-success {
  display: none;
  background-color: #85fdb3;
  padding: 3rem;
  width: 100%;
  min-width: 28.5rem;
  max-width: 100%;
  inset: 0;
  margin: auto;
  height: 100%;
  position: absolute;
  z-index: 110;
  top: -200%;
}

.single-letter {
  display: inline-block;
}

@media only screen and (max-width: 1200px) {
  .contact-link {
    font-size: 3rem;
  }

  .modal-close {
    top: 5px;
  }
}
